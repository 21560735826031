@import '../../modern-ui/_common/colors/colors.css';
.listHeaderSpacing {
  margin: 24px 0 0;
}
.line {
  display: flex;
  position: relative;
  color: var(--black);
  align-items: center;
}
.lineItem,
.lineItemHeader {
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  width: calc(50% - 23px);
  margin: 0 14px 0 0;
}
.lineItem_mobile,
.lineItemHeader_mobile {
  width: 50%;
}
.lineItem_mobile:first-child,
.lineItemHeader_mobile:first-child {
  padding-right: 14px;
  width: calc(50% - 32px);
}
.lineItem_undeletableMobile:nth-child(2),
.lineItemHeader_undeletableMobile:nth-child(2) {
  width: calc(50% + 32px);
}
.lineItem {
  white-space: nowrap;
}
.ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.listHeaderFont {
  font-size: 15px;
  color: var(--grey);
}
.listItemLayout {
  margin: 12px 0 0 0;
  min-height: 36px;
}
.badgeSpacing {
  margin: 4px;
}
.trashButtonPositioning {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.itemEnter {
  opacity: 0.01;
  height: 0;
}
.itemExitActive {
  opacity: 0.01;
  height: 0;
  transition: height 0.2s ease, opacity 0.2s ease;
}
.itemEnterActive {
  opacity: 1;
  height: 52px;
  transition: height 0.2s ease, opacity 0.2s ease;
}
.itemExit {
  opacity: 1;
  height: 52px;
}
