@import '../../modern-ui/_common/colors/colors.css';
icon {
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}
icon[type='trash'] {
  background-image: url('../../assets2/images/ic_trash_midle.svg');
}
icon[type='arrow-down'] {
  width: 10px;
  height: 10px;
  background-image: url('../../assets2/images/selector.svg');
}
icon[type='copy'] {
  background-image: url('../../ui/icon/images/ic_clipboard.svg');
}
icon[type='add'] {
  background-image: url('../../assets2/images/ic_add_small.svg');
  background-size: auto;
}
icon[rounded] {
  border-radius: 50%;
}
icon[type='duplicate'] {
  background-image: url('../../ui/icon/images/duplicate.svg');
}
icon[type='cross'] {
  background-image: url('../../assets2/images/ic_close.svg');
}
icon[type='receipt-gray'] {
  background-image: url('../../ui/icon/images/receipt-gray.svg');
}
icon[type='receipt-blue'] {
  background-image: url('../../ui/icon/images/receipt-blue.svg');
}
icon[type='horizontal-dots'] {
  background-image: url('../../ui/icon/images/horizontal_dots.svg');
}
icon[type='cross-white'] {
  background-image: url('../../ui/icon/images/ic_cross_white.svg');
}
icon[type='comment-plugin'] {
  background-image: url('../../common/plugins/assets/images/icons/ic_comment.svg');
}
icon[type='blue-email'] {
  background-image: url('../../ui/icon/images/blue_email.svg');
}
icon[type='blue-phone'] {
  background-image: url('../../ui/icon/images/blue_phone.svg');
}
icon[type='google-sheet'] {
  background-image: url('../../ui/icon/images/ic_gsheets_round.svg');
}
icon[type='handover'] {
  background-image: url('../../ui/icon/images/ic_handover_round.svg');
}
icon[type='pro'] {
  background-image: url('../../ui/icon/images/pro.svg');
}
icon[type='arrow-left'] {
  background-image: url('../../ui/icon/images/ic_arr.svg');
}
icon[type='arrow-right'] {
  background-image: url('../../ui/icon/images/ic_arr.svg');
  transform: rotate(180deg);
}
icon[type='woman'] {
  background-image: url('../../ui/icon/images/woman.png');
  width: 28px;
  height: 22px;
}
icon[type='robot'] {
  background-image: url('../../ui/icon/images/robot.png');
  width: 28px;
  height: 22px;
}
icon[type='collision'] {
  background-image: url('../../ui/icon/images/collision.png');
  width: 28px;
  height: 22px;
}
icon[type='big-add'] {
  background-image: url('../../ui/icon/images/bigest-add.svg');
}
icon[type='big-add-gray'] {
  background-image: url('../../ui/icon/images/bigest-add-gray.svg');
}
icon[type='big-add-blue'] {
  background-image: url('../../ui/icon/images/bigest-add-blue.svg');
}
icon[type='dotted'] {
  background-image: url('../../ui/icon/images/dotted.svg');
}
icon[type='fb-logo'] {
  background-image: url('../../ui/icon/images/fb-small.svg');
}
icon[type='link-blue'] {
  background-image: url('../../ui/icon/images/link-blue.svg');
}
icon[type='big-big-add'] {
  background-image: url('../../ui/icon/images/add_big.svg');
}
icon[type='google-logo'] {
  background-image: url('../../ui/icon/images/google_logo.svg');
}
icon[type='open-in-messenger'][icon-color='white'] {
  background-image: url('../../ui/icon/images/ic_external_white.svg');
}
icon[type='open-in-messenger'][icon-color='black'] {
  background-image: url('../../ui/icon/images/ic_external_black.svg');
}
icon[type='open-in-messenger'][icon-color='blue'] {
  background-image: url('../../ui/icon/images/ic_external_blue.svg');
}
icon[type='open-in-messenger'][icon-color='black-opacity'] {
  background-image: url('../../ui/icon/images/ic_external_black.svg');
  opacity: 0.5;
}
icon[type='fb-messenger-logo'][icon-color='white'] {
  background-image: url('../../ui/icon/images/ic-fb-ms_white.svg');
}
icon[type='fb-messenger-logo'][icon-color='black'] {
  background-image: url('../../ui/icon/images/ic-fb-ms_black.svg');
}
icon[type='fb-messenger-logo'][icon-color='black-opacity'] {
  background-image: url('../../ui/icon/images/ic-fb-ms_black.svg');
  opacity: 0.5;
}
icon[type='emoji'] {
  width: 28px;
  height: 22px;
}
icon[type='emoji'][emoji-name='person-raising-both-hands-in-celebration'] {
  background-image: url('../../ui/icon/images/emoji/person-raising-both-hands-in-celebration.png');
}
icon[type='emoji'][emoji-name='raised-hand'] {
  background-image: url('../../ui/icon/images/emoji/raised-hand.png');
}
icon[type='emoji'][emoji-name='flexed-biceps'] {
  background-image: url('../../ui/icon/images/emoji/flexed-biceps.png');
}
icon[type='emoji'][emoji-name='waving-hand-sign'] {
  background-image: url('../../ui/icon/images/emoji/waving-hand-sign.png');
}
icon[type='emoji'][emoji-name='robot-face'] {
  background-image: url('../../ui/icon/images/robot.png');
}
icon[type='inbox'] {
  background-image: url('../../ui/icon/images/ic_inbox.svg');
}
icon[type='inbox'][icon-color='light-gray'] {
  background-image: url('../../ui/icon/images/ic_inbox_light_gray.svg');
}
icon[type='beta'] {
  background-image: url('../../ui/icon/images/beta.svg');
}
icon[inline='true'] {
  display: inline-block;
}
icon[align='center'] {
  vertical-align: -5px;
}
.animate-fade-in-end {
  transform: scale(1);
}
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes swing {
  15% {
    transform: translateX(3px);
    -webkit-transform: translateX(3px);
  }
  30% {
    transform: translateX(-3px);
    -webkit-transform: translateX(-3px);
  }
  50% {
    transform: translateX(2px);
    -webkit-transform: translateX(2px);
  }
  65% {
    transform: translateX(-2px);
    -webkit-transform: translateX(-2px);
  }
  80% {
    transform: translateX(1px);
    -webkit-transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
}
.disabled {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
.disabled * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
.disabled .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
.appear-icon-button-on-hover icon-button {
  opacity: 0;
  transform: scale(0.3);
  transition: transform cubic-bezier(0.2, 0.7, 0.5, 1) 200ms, opacity cubic-bezier(0.2, 0.7, 0.5, 1) 200ms;
}
.appear-icon-button-on-hover:hover icon-button {
  opacity: 1;
  transform: scale(1);
}
icon-button {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
icon-button > * {
  pointer-events: none;
}
icon-button[size='small'] {
  width: 21px;
  height: 21px;
  padding: 3px;
}
icon-button[size='small'] > icon {
  width: 15px;
  height: 15px;
}
icon-button[size='sub-medium'] {
  width: 24px;
  height: 24px;
  padding: 3px;
}
icon-button[size='sub-medium'] > icon {
  width: 18px;
  height: 18px;
}
icon-button[size='medium'] {
  width: 28px;
  height: 28px;
  padding: 2px;
}
icon-button[size='medium'] > icon {
  width: 24px;
  height: 24px;
}
icon-button[disabled] {
  pointer-events: none !important;
  opacity: 0.6 !important;
  -webkit-user-select: none;
          user-select: none;
}
icon-button[disabled] * {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
icon-button[disabled] .disabled-overrided-button {
  pointer-events: all !important;
  -webkit-user-select: all !important;
          user-select: all !important;
  opacity: 1 !important;
}
icon-button[rounded] {
  border-radius: 50%;
  overflow: hidden;
}
icon-button[actionable='true'] > icon {
  opacity: 0.3;
}
icon-button[actionable='true']:hover > icon {
  opacity: 1;
}
icon-button[type='square-rounded'] {
  border-radius: 8px;
  background-color: rgba(20, 20, 20, 0.16);
}
icon-button[type='square-rounded'] > icon {
  width: 16px;
  height: 16px;
}
.layout {
  position: relative;
  height: 100%;
  padding-bottom: 20px;
}
.layout_mobile {
  padding-bottom: 0;
}
.userNameMakeUp {
  font-size: 23px;
  line-height: 28px;
  margin: 20px 44px 40px;
  color: var(--black);
}
.userNameMakeUp_mobile {
  margin: 20px 16px 16px;
}
.editorsBoxSpacing {
  padding: 0 16px 36px 24px;
}
.editorsBoxSpacing_mobile {
  padding: 0 16px 36px;
}
.searchBox {
  padding-top: 2px;
  width: 100%;
}
.searchBox.narrow {
  width: calc(100% - 40px);
}
.searchBox_mobile {
  width: 100%;
}
.flowFilter {
  padding: 2px 0 14px;
  width: calc(100% - 40px);
}
.addButtonLayout {
  width: 96px;
  padding: 9px 0 0;
}
.addButtonLayout_mobile {
  width: 100%;
}
.bottomButtonBox {
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 92px;
  background: linear-gradient(181.07deg, rgba(255, 255, 255, 0) -10.26%, #ffffff 46.62%);
  z-index: var(--zindex-over-content);
  padding: 16px 40px 40px;
  border-radius: 0 0 8px 8px;
}
.deleteAttributesButton {
  position: absolute;
  left: 40px;
  bottom: 40px;
  z-index: var(--zindex-over-mask);
}
.addButton_mobile {
  width: 100%;
  display: flex;
  justify-content: center;
}
.bottomButtonBox.leftSide {
  flex-direction: row;
}
.upgradeTitle {
  margin-left: 44px;
  position: relative;
  top: -24px;
}
.upgradeTitleText {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
